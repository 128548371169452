@import '../shared/colors';
@import '../shared/layout';
@import '../shared/breakpoints';

$slider-height: 15rem;
$slider-height-medium: 22rem;
$menu-height: 4rem;

.wrapper {
  @media screen and (max-width: $small) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  box-sizing: border-box;
  z-index: 1;
  background-color: $background-base;
  left: 0;
  height: 100vh;
  width: $menu-width;
  padding: $page-padding 1.5rem;
  font-size: 0.8rem;
  letter-spacing: 0.025rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1.75rem;
    text-transform: uppercase;
  }

  li {
    list-style: none;
    list-style-type: none;
    line-height: 2rem;
  }
}

.hamburger {
  @media screen and (min-width: $small + 1px) {
    display: none;
  }

  @include no-select;

  outline: none;
  cursor: pointer;
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  background-color: $background-base;
  width: 100%;
  min-height: $menu-height;
}

.button {
  margin-top: -0.3rem;
}

.slider {
  @media screen and (min-width: $small) {
    line-height: 2rem;
    height: $slider-height-medium;
  }

  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: $background-base;
  padding: 0 1rem;
  width: 100%;
  height: $slider-height;
  margin-top: -#{$slider-height};
  border-bottom-width: 0.0625rem;
  border-bottom-color: $light-gray;
  border-bottom-style: solid;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 0.5rem;
  }

  li {
    list-style: none;
    list-style-type: none;
    line-height: 2rem;
    font-size: 0.8rem;
    text-transform: uppercase;

    @media screen and (min-width: $small) {
      line-height: 3rem;
    }
  }
}

.slideIn {
  transform: translateY(calc(#{$menu-height - 0.5rem} + #{$slider-height}));
  transition: transform .3s ease-in-out;
}

.slideOut {
  transform: translateY(-10rem);
  transition: transform .3s ease-in-out;
}

.titleName {
  display: block;
}