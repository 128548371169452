@import '../shared/layout.scss';
@import '../shared/breakpoints.scss';

.wrapper {
  @media screen and (max-width: $small) {
    padding-bottom: 1rem;
    flex-direction: column;
    align-self: flex-start;
  }

  font-size: 0.625rem;
  font-style: italic;
  letter-spacing: 0.025rem;
}

.text {
  margin-bottom: 0.5rem;
  line-height: 0.9rem;
}

.divider {
  width: 30%;
}
