html, body, #root {
  height: 100%;
  color: #111111;
  background-color: #fffdfc;
}

h1 {
  margin: 0;
  font-family: 'Gothic A1', serif;
  letter-spacing: 0.185rem;
  font-size: 1.1rem;
  line-height: 1.75rem;
}

a {
  text-decoration: none;
  color: #111111;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

.hamburger {
  padding: 1rem;
  width: 3rem;
  outline: none;
}

.hamburger::-moz-focus-inner {
  border: 0;
}

.hamburger:focus {
  outline: none;
}

.hamburger:hover {
  opacity: 1;
}

.hamburger-box {
  width: 2.5rem;
  height: 0.625rem;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  width: 1.875rem;
  border-radius: 0;
}

.hamburger.is-active:hover, .hamburger:hover {
  opacity: 1;
}

@media screen and (max-width: 1025px) {
  h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.2rem;
  }
}
