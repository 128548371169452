@import '../components/shared/breakpoints.scss';

@mixin allPathsImage {
  display: block;
  transition: opacity 0.375s;

  @media screen and (max-width: $small) {
    height: auto;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    height: auto;
    width: 100%;
  }
}

.hand {
  @include allPathsImage;

  height: 65vh;
  margin-bottom: 10vh;
  margin-left: 4vw;
  margin-right: 2vw;
  opacity: 0;
  width: auto;
}

.hyperspace {
  @include allPathsImage;

  height: auto;
  margin-right: 7vw;
  margin-top: 40vh;
  opacity: 0;
  width: 27vw;
}

.reality {
  @include allPathsImage;

  height: auto;
  margin-right: 5vw;
  opacity: 0;
  width: 50vw;
}

.eye {
  @include allPathsImage;

  height: auto;
  margin-bottom: 20vh;
  margin-right: 3vw;
  opacity: 0;
  width: 30vw;
}

.galaxy {
  @include allPathsImage;

  height: auto;
  margin-bottom: 20vh;
  margin-right: 3vw;
  opacity: 0;
  width: 50vw;
}

.waterfall {
  @include allPathsImage;

  height: 55vh;
  margin-right: 5vw;
  margin-top: 25vh;
  opacity: 0;
  width: auto;
}

.hair {
  @include allPathsImage;

  height: auto;
  margin-right: 5vw;
  margin-top: 7vh;
  opacity: 0;
  width: 40vw;
}

.butterfly {
  @include allPathsImage;

  height: 70vh;
  margin-right: 3vw;
  opacity: 0;
  width: auto;
}

.snowStorm {
  @include allPathsImage;

  height: auto;
  margin-right: 4vw;
  opacity: 0;
  width: 50vw;
}

.fox {
  @include allPathsImage;

  height: auto;
  margin-right: 4vw;
  opacity: 0;
  width: 50vw;
}

.moon {
  @include allPathsImage;

  height: auto;
  margin-right: 4vw;
  margin-top: 10vh;
  opacity: 0;
  width: 35vw;
}

.prism {
  @include allPathsImage;

  height: auto;
  margin-bottom: 10vh;
  margin-right: 3vw;
  opacity: 0;
  width: 40vw;
}

.lines {
  @include allPathsImage;

  height: 65vh;
  margin-right: 6vw;
  margin-top: 5vh;
  opacity: 0;
  width: auto;
}

.clouds {
  @include allPathsImage;
  height: auto;
  margin-right: 4vw;
  margin-top: 30vh;
  opacity: 0;
  width: 40vw;
}

.carp {
  @include allPathsImage;
  height: auto;
  margin-right: 6vw;
  margin-top: 5vh;
  opacity: 0;
  width: 48vw;
}

.flowers {
  @include allPathsImage;
  height: 50vh;
  margin-right: 5vw;
  margin-top: 15vh;
  opacity: 0;
  width: auto;
}

.waterShine {
  @include allPathsImage;
  height: auto;
  margin-bottom: 10vh;
  margin-right: 6vw;
  opacity: 0;
  width: 50vw;
}

.memory {
  @include allPathsImage;
  height: auto;
  margin-bottom: 10vh;
  margin-right: 4vw;
  opacity: 0;
  width: 30vw;
}

.tree {
  @include allPathsImage;
  height: 65vh;
  margin-right: 4vw;
  margin-top: 4vw;
  opacity: 0;
  width: auto;
}

.livingWater {
  @include allPathsImage;
  height: 70vh;
  margin-right: 4vw;
  margin-top: 4vw;
  opacity: 0;
  width: auto;
}

.rect {
  @include allPathsImage;
  @media screen and (max-width: $small) {
    display: none;
  }
}
