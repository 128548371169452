@import '../components/shared/breakpoints.scss';

@mixin emptyFieldsImage {
  display: block;
  transition: opacity 0.375s;

  @media screen and (max-width: $small) {
    height: auto;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    height: auto;
    width: 100%;
  }
}

.vertical {
  @include emptyFieldsImage;

  height: 60vh;
  margin-bottom: 10vh;
  margin-left: 2.5vw;
  margin-right: 5vw;
  opacity: 0;
  width: auto;
}

.horizontalLarge {
  @include emptyFieldsImage;

  height: auto;
  margin-right: 2vw;
  margin-left: 2.5vw;
  opacity: 0;
  width: 40vw;
}


.horizontalMedium {
  @include emptyFieldsImage;

  height: auto;
  margin-right: 7vw;
  margin-bottom: 1vw;
  opacity: 0;
  width: 37vw;
}