@import '../shared/layout.scss';
@import '../shared/breakpoints.scss';

$img-margin: 1.5rem;

.fullSize {
  display: block;
  height: 100%;
  margin-right: $img-margin;
  transition: opacity 0.375s;
  width: auto;

  @media screen and (max-width: $small) {
    height: auto;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    width: 100%;
  }
}

.wrapper {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  height: 100%;
  overflow: initial;
  padding: $page-padding 0 $page-padding calc(#{$menu-width} + 2rem);
  background-color: $background-base;

  @media screen and (max-width: $small) {
    display: block;
    padding: 4.25rem 1rem;
  }
}

.description {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  letter-spacing: 0.025rem;
  line-height: 1.25rem;
  width: 25rem;
  overflow-y: auto;
  padding: 0.3rem 1.5rem;

  p:first-child {
    margin-block-start: 0;
  }

  @media screen and (max-width: $small) {
    padding: 0;
    width: 100%;
  }

  h2 {
    font-family: 'Gothic A1', serif;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    line-height: 1.2rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }
}

.copyright {
  display: none;

  @media screen and (max-width: $small) {
    display: flex;
  }
}

.tag {
  margin-bottom: 3rem;
}