@import '../shared/layout.scss';
@import '../shared/breakpoints.scss';

.wrapper {
  padding: $page-padding 0 $page-padding calc(#{$menu-width} + 2rem);
  font-size: 0.9rem;
  line-height: 1.25rem;
  letter-spacing: 0.025rem;
  max-width: 40rem;
  color: $black;
  background-color: $background-base;

  @media screen and (max-width: $small) {
    max-width: 100%;
    padding: calc(#{$page-padding} + 3rem) 1rem;
  }
}

.smaller {
  font-size: 0.6rem;
}